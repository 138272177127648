<template>
    <div>
        <!--    状态0-->
        <div class="appointment-time" v-if="type===0">
            <!-- 翻译：预约时间-->
            <h1>{{ $t('vipRecommend.appointmentTime') }}</h1>
            <p class="tip">请选择一个时间段接受您的预约申请</p>
            <ul class="time-list">
                <li><label class="radio-label" for="a"><input id="a" name="time" type="radio">2021年1月22日 11:30</label>
                </li>
                <li><label class="radio-label" for="b"><input id="b" name="time" type="radio">2021年1月22日 11:30</label>
                </li>
                <li><label class="radio-label" for="c"><input id="c" name="time" type="radio">2021年1月22日 11:30</label>
                </li>
                <li><label class="radio-label" for="d"><input id="d" name="time" type="radio">2021年1月22日 11:30</label>
                </li>
                <li><label class="radio-label" for="e"><input id="e" name="time" type="radio">2021年1月22日 11:30</label>
                </li>
            </ul>

            <div class="leave-message">
                <h1>关于预约给客服的留言</h1>
                <textarea cols="30" id="" name="" placeholder="请输入" rows="10">
      </textarea>
            </div>
            <div class="is-btn-group">
                <CustomButton @click="sendMsg" class="fl" type="black">同意</CustomButton>
                <CustomButton @click="sendMsg" class="fl" style="margin-left:17px; " type="white-black">拒绝
                </CustomButton>
            </div>
        </div>
        <!--    状态1-->
        <div class="appointment-time" v-else-if="type===1">
            <h1>{{ $t('vipRecommend.appointmentTime') }}</h1>
            <div class="time">2021年1月22日</div>
            <div class="s-leave-message">
                <!--翻译：关于预约给客服的留言-->
                <h2>{{ $t('vipRecommend.messageAboutBookingToCustomerService') }}</h2>
                <p class="over4">
                    给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言</p>
            </div>
            <div class="cancel-yy">
                <CustomButton class="btn" type="white">{{$t('myProject.cancelReservation')}}</CustomButton>
                <span>{{$t("bulletFrame.appointmentTwoHours")}}</span>
            </div>
        </div>
        <!--    状态2-->
        <div class="appointment-time" v-else-if="type===2">
            <h1>{{ $t('vipRecommend.appointmentTime') }}</h1>
            <div class="time">2021年1月22日</div>
            <div class="s-leave-message">
                <!--翻译：关于预约给客服的留言-->
                <h2>{{ $t('vipRecommend.messageAboutBookingToCustomerService') }}</h2>
                <p class="over4">
                    给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言</p>
            </div>
            <div class="form-wrap">
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t('vipRecommend.mobileNumber') }}</div>
                    <div class="ita-input-front">
                        <span>+</span>
                        <input class="s-input" disabled="true" type="text" value="86">
                        <div class="v-line"></div>
                        <input class="iif-input" placeholder="请输入" type="text">
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t('vipRecommend.email') }}</div>
                    <div class="ita-input-front">
                        <input class="iif-input" placeholder="请输入" type="text">
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>WhatsApp</div>
                    <div class="ita-input-front">
                        <input class="iif-input" placeholder="请输入" type="text">
                    </div>
                </div>
            </div>
            <div class="people-wrap">
                <img alt="" class="people-head" src="@/assets/images/1.png">
                <span class="people-name">Andrew Norton</span>
                <span class="project-name">访谈项目名称</span>
            </div>
            <div class="evaluate">
                <div class="evaluate-star">
                    <span>{{$t('myProject.evaluationInterview')}}<!--本次访谈评价--></span>
                    <el-rate :colors="colors" :max="3" class="star" v-model="star" void-color="#D6D6D6"></el-rate>
                </div>
                <div class="evaluate-content">
          <textarea @input="txtChange" cols="30" id="evaluate" maxlength="140" name="evaluate" placeholder="请输入"
                    rows="10"
                    v-model="txtContent"></textarea>
                    <span class="numberV">{{ txtVal }}/140</span>
                </div>
            </div>
            <CustomButton style="margin-top: 31px" type="black">{{$t('myProject.submit')}}<!--提交--></CustomButton>
        </div>
        <!--    状态3-->
        <div class="appointment-time" v-else-if="type===3">
            <h1>{{ $t('vipRecommend.appointmentTime') }}</h1>
            <div class="time">2021年1月22日</div>
            <div class="s-leave-message">
                <!--翻译：关于预约给客服的留言-->
                <h2>{{ $t('vipRecommend.messageAboutBookingToCustomerService') }}</h2>
                <p class="over4">
                    给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言给客服的留言</p>
            </div>
            <div class="evaluate-result">
                <h1>{{$t('myProject.evaluationInterview')}}<!--本次访谈评价--></h1>
                <div class="evaluate-result-content">
                    <img alt="" src="@/assets/images/1.png">
                    <div class="erc-r fl">
                        <div class="erc-r-t">
                            <div class="erc-r-t-l">
                                <div class="erc-r-t-l-name">Richard Pompa</div>
                                <el-rate :colors="colors" :max="3" disabled v-model="star"
                                         void-color="#D6D6D6"></el-rate>
                            </div>
                            <div class="erc-r-t-r">2021-02-01 16:31:35</div>
                        </div>
                        <div class="erc-r-b over4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
                            Proin gravida dolor sit amet lacus，Lorem ipsum
                            dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
                            dolor sit amet lacusLorem ipsum dolor sit
                            amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit
                            amet lacus
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--    状态4-->
        <div class="appointment-time" v-else-if="type===4">
            <h1>{{ $t('vipRecommend.appointmentTime') }}</h1>
            <div class="time">2021年1月22日</div>
            <div class="form-wrap">
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t('vipRecommend.mobileNumber') }}</div>
                    <div class="ita-input-front">
                        <span>+</span>
                        <input class="s-input" disabled="true" type="text" value="86">
                        <div class="v-line"></div>
                        <input class="iif-input" placeholder="请输入" type="text">
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>{{ $t('vipRecommend.email') }}</div>
                    <div class="ita-input-front">
                        <input class="iif-input" placeholder="请输入" type="text">
                    </div>
                </div>
                <div class="input-text-area">
                    <div class="ita-label"><span>* </span>WhatsApp</div>
                    <div class="ita-input-front">
                        <input class="iif-input" placeholder="请输入" type="text">
                    </div>
                </div>
            </div>
            <div class="leave-message">
                <h1>关于预约给客服的留言</h1>
                <textarea cols="30" id="q" name="" placeholder="请输入" rows="10">
      </textarea>
            </div>
            <div class="is-btn-group">
                <CustomButton @click="sendMsg" class="fl" type="black">同意</CustomButton>
                <CustomButton @click="sendMsg" class="fl" style="margin-left:17px; " type="black">拒绝</CustomButton>
            </div>
        </div>


    </div>
</template>

<script>
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";

    const cityOptions = ['上海', '北京', '广州', '深圳'];
    export default {
        name: "AppointmentTime",
        data() {
            return {
                star: null,
                txtVal: 0,//文本域计数
                txtContent: '',
                checkedCities: ['上海',],
                cities: cityOptions,
                colors: ['#000000']
            };
        },
        props: {
            type: {
                type: Number,
                default: 2,
            }
        },
        components: {
            CustomButton
        },
        methods: {
            sendMsg() {
                this.$emit('func', this.msg)
            },
            txtChange() {
                this.txtVal = this.txtContent.length;
            }

        }
    }
</script>

<style scoped>

    .appointment-time {
        margin-top: 54px;
    }

    .appointment-time > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .appointment-time > .tip {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
    }

    .time-list {
        margin-top: 23px;
    }

    .time-list > li {
        margin-bottom: 26px;
    }

    .time-list > li:last-child {
        margin-bottom: 0;
    }

    .leave-message {
        margin-top: 55px;

    }

    .leave-message > h1 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;

    }

    .leave-message textarea {
        width: 695px;
        height: 117px;
        background: #FAFAFA;
        border-radius: 4px;
        margin-top: 21px;
        resize: none;
        padding: 17px 26px;
        box-sizing: border-box;
    }

    .is-btn-group {
        margin-top: 40px;
        overflow: hidden;
    }


    .radio-label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    /*111111111111*/
    .appointment-time .time {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .s-leave-message {
        margin-top: 30px;
    }

    .s-leave-message > h2 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .s-leave-message > p {
        margin-top: 7px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }

    .cancel-yy {
        margin-top: 54px;
    }

    .cancel-yy .btn {
        display: inline-block;
    }

    .cancel-yy > span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 50px;
        color: #949494;
        margin-left: 33px;
    }

    /*1111111111111*/
    .form-wrap {
        margin-top: 57px;

    }

    .input-text-area {
        margin-bottom: 31px;
    }

    .input-text-area:last-child {
        margin-bottom: 0;
    }

    .ita-label {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
    }

    .ita-label > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .ita-input-front {
        width: 536px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 26px;
        line-height: 50px;
    }

    .s-input {
        display: inline-block;
        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 60px;
    }

    .v-line {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 22px;
        border: 1px solid #D6D6D6;
        margin-right: 45px;
    }

    .ita-input-front > span {
        display: inline-block;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .iif-input {
        display: inline-block;

        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 300px;
    }

    .people-wrap {
        height: 49px;
        margin-top: 31px;
        line-height: 49px;
    }

    .people-wrap .people-head {
        display: inline-block;
        width: 49px;
        height: 49px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-wrap .people-name {
        display: inline-block;

        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-left: 12px;
    }

    .people-wrap .project-name {
        display: inline-block;

        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-left: 54px;

    }

    .evaluate {
        margin-top: 36px;

    }

    .evaluate-star {
        height: 25px;
        line-height: 25px;
    }

    .evaluate-star > span {
        display: inline-block;
        line-height: 25px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 30px;
    }

    .evaluate-star > .star {
        display: inline-block;
        line-height: 20px;


    }

    .evaluate-content {
        width: 695px;
        height: 121px;
        background: #FAFAFA;
        border-radius: 4px;
        margin-top: 30px;
        position: relative;
    }

    .evaluate-content textarea {
        width: 99%;
        height: 90px;
        background-color: transparent;
        resize: none;
        padding: 17px 26px;

        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .numberV {
        position: absolute;
        right: 24px;
        bottom: 13px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .evaluate-result {
        margin-top: 57px;
        padding: 27px 0 57px 0;
        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
    }

    .evaluate-result > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .evaluate-result-content {
        overflow: hidden;
        margin-top: 30px;
    }

    .evaluate-result-content > img {
        float: left;
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .erc-r {
        margin-left: 13px;
        padding-top: 8px;

    }

    .erc-r-t {
        width: 988px;
        height: 60px;
    }

    .erc-r-t-l {
        float: left;
        height: 60px;
    }

    .erc-r-t-l-name {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 20px;
        color: #000000;
        margin-bottom: 14px;
    }

    .erc-r-t-r {
        float: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 60px;
    }

    .erc-r-b {
        margin-top: 20px;
        width: 988px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 23px;
        color: #707070;
    }


    /*radio样式*/
    input[type=radio] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        width: 16px;
        height: 16px;
        bottom: 3px;
        margin-right: 14px;

        background: transparent;
        border: 1px solid #8A8A8A;
        /*border-radius: 4px;*/
        outline: none;
        cursor: pointer;
    }

    input[type=radio] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        background: url("../../assets/images/radio_n@2x.png") no-repeat;
        background-size: 100% 100%;
        border: 0;
    }

    input[type=radio]:after {
        content: '';
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        text-align: center;
        line-height: 18px;
    }

    input[type=radio]:checked:after {
        border-radius: 0;
        opacity: 1;
    }

    input[type=radio]:checked {
        background: url("../../assets/images/radio_s@2x.png") no-repeat;
        background-size: 100% 100%;
    }

</style>
